import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

export default function NinthPage(){

	const navigate = useNavigate();

	const [blurState, setBlurState] = useState([5, 5, 5, 5, 5]);

	const handleUnblur = (index) => {
		const newBlurState = [...blurState];
		newBlurState[index] = 0;
		setBlurState(newBlurState);
	}

	return(
		<div className='App'>
			<h1 className='title' id='animated'>Πως Πρωτοσυνάντησα τον Ώριμο χαρακτήρα σου</h1>

			<br />
			
			<p
				className='p-container'
				style={{color:'rgb(22, 22, 22)', filter:`blur(${blurState[0]}px)`}}
				onClick={() => handleUnblur(0)}
			>
				Την ίδια ημέρα, απόγευμα, μου έστειλες μήνυμα να πάμε βόλτα... να μιλήσουμε. Η καρδιά μου πλημμύρισε τρόμο. 
				Το μυαλό μου σκεφτόταν τα χειρότερα. "Θα με χωρίσει..." έλεγα από μέσα μου. Δεν σε ήξερα για πολύ καιρό και 
				πάλι ένιωθα σαν να έχανα το άλλο μου μισό. Και πάλι άξιζε να πολεμήσω για σένα. Στην περίπτωση που θα έλεγες 
				αυτό, θα πολεμούσα. Δεν γινόταν να μην προσπαθήσω καν, δεν θα παρεδιδόμουν τόσο εύκολα.
			</p>

			<br />

			<p
				className='p-container'
				style={{color:'rgb(22, 22, 22)', filter:`blur(${blurState[1]}px)`}}
				onClick={() => handleUnblur(1)}
			>
				Συναντηθήκαμε δίπλα από το Hondos. Στο γνωστό μας σημείο που συναντιόμασταν πάντα στην αρχή. Καθώς, 
				στη συνέχεια, αυτό το σημείο έγινε μπροστά από το σπίτι σου. Ξεκίνησε η συνάντηση με κρύο ύφος, που 
				πάγωνε τις καρδιές μας. Αυτές αναζητούσαν ζεστασιά, αλλά το μυαλό και τα προβλήματα δεν ευνοούσαν 
				τις πεποιθήσεις τους. Αρχίσαμε να περπατάμε μαζί προς την Σπιανάδα. Τι να πω... τι να κάνω για να 
				βελτιώσω την κατάσταση; Να μην σε χάσω; "Θεέ μου παντοδύναμε... βοήθησέ με!" Και εντέλει βοήθησε. 
				Σε αυτό το σημείο ακριβώς είναι που το σχοινάκι χόντρυνε λίγο περισσότερο!
			</p>

			<br />
			
			<p
				className='p-container'
				style={{color:'rgb(22, 22, 22)', filter:`blur(${blurState[2]}px)`}}
				onClick={() => handleUnblur(2)}
			>
				Καθώς περπατούσαμε, μου ανοίχτηκες με ένα γλυκό ύφος. Ξεκίνησες να μου εξηγάς την κατάσταση με όσες λεπτομέρειες 
				χρειαζόταν να ξέρω. Έγινες διάφανη σαν μια πεντακάθαρη λίμνη που μπορείς να δεις τα ψάρια μέσα να κολυμπάνε δίχως 
				έννοιες. Μου ανέφερες το συμβάν με τον συγχωρεμένο και στη συνέχεια τη συμπεριφορά και τον χαρακτήρα της μαμάς 
				σου. Την πίεση, την ένταση στο σπίτι, το άγχος... Δεν τα είπες αυτά για να δικαιολογηθείς, μόνο για να εξηγήσεις 
				το γιατί συμπεριφέρθηκες έτσι. Αμέσως μετά απολογήθηκες θερμά, με δάκρυα να πέφτουν από τα γλυκά, πανέμορφα 
				πράσινα ματάκια σου.
			</p>

			<br />

			<p
				className='p-container'
				style={{color: 'rgb(22, 22, 22)', filter: `blur(${blurState[3]}px)`}}
				onClick={() => handleUnblur(3)}
			>
				"Δεν έπρεπε να σου συμπεριφερθώ έτσι... πραγματικά ήταν red flag εκ μέρους μου. Συγγνώμη, πραγματικά...". 
				Είπες και άλλα, που δυστυχώς μου διαφεύγουν. Το μόνο που θυμάμαι είναι αυτά τα λόγια και τα δάκρυά σου. 
				Και αυτά ήταν αρκετά για να σε συγχωρέσω. Δεν θα νικούσε ο εγωισμός μου σε μια τόσο σοβαρή κατάσταση. 
				Σε ήθελα και σε θέλω. Πάντα θα σε θέλω. Είμαι διατεθειμένος να πολεμήσω για το σχοινάκι που τώρα 
				δυνάμωσε. Να πολεμήσω με νύχια και με δόντια. Αυτή η κίνηση σου ήταν ό,τι χρειαζόμουν για να βεβαιωθώ 
				ότι είσαι πολύ ώριμο κορίτσι... και ανυπομονώ να δω την ώριμη γυναίκα που θα γίνεις και θα είσαι στο πλάι μου.
			</p>

			<br />

			<p
				className='p-container'
				style={{color: 'rgb(22, 22, 22)', filter: `blur(${blurState[4]}px)`}}
				onClick={() => handleUnblur(4)}
			>
				Στη συνέχεια τα βρήκαμε. Η συζήτηση πλέον έγινε ευχάριστη, γεμάτη γέλια, χαρά, και τα πρόσωπά μας είχαν ένα 
				χαμόγελο που προερχόταν από την καρδιά μας. Εκείνη τη στιγμή είχα έναν θησαυρό μπροστά μου. Τα μάτια σου έλαμπαν, 
				και το εκθαμβωτικό σου χαμόγελο με τύφλωνε. Αν συνεχίσουμε να αντιμετωπίζουμε τα εμπόδια μαζί, σαν εμείς ενάντια 
				σε αυτά, τότε κάθε εμπόδιο θα μετατραπεί σε ευλογία. Ένα μέσο για να μάθουμε ο ένας τον άλλον καλύτερα και να έρθουμε πιο κοντά!
			</p>

			<button className='myBtn' onClick={() => navigate('/game3')}>Go To The Next Page</button>

			<br />

		</div>
	);
}