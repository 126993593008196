import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SixthPage(){
	const navigate = useNavigate();

	const [blurState, setBlurState] = useState([5, 5, 5, 5, 5]);

	const handleUnblur = (index) => {
		const newBlurState = [...blurState];
		newBlurState[index] = 0; // Unblur the clicked paragraph
		setBlurState(newBlurState);
	};

	return(
		<div className="App">
			<h1 className="title">Το πρώτο μας φιλί</h1>

			<br />

			<p
				className='p-container'
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[0]}px)`}}
				onClick={() => handleUnblur(0)}
			>
				Μέσα στη βδομάδα που σου έκανα κρέπες ήμαστε. Ημέρα τετάρτη. Εκείνη τη μέρα δεν πήγαμε βόλτα αλλά με προσκάλεσες σπίτι σου.
				Χτυπάω την πόρτα και σε αντικρίζω με ένα μεγάλο χαμόγελο, η ψυχή μου σε λαχταρούσε και θαύμασε την θηλικότητα σου, 
				την ομορφιά σου, τα μάτια σου. Είναι μια εικόνα χαραγμένη στις αναμνήσεις μου η όψη σου όταν άνοιξες την πόρτα.
				Αν το μυαλό μου ήταν μουσείο με εικόνες, αυτή μπορεί να είναι η πιο ακριβή και προστατευμένη απο όλες.
			</p>

			<br />

			<p
				className='p-container'
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[1]}px)`}}
				onClick={() => handleUnblur(1)}
			>
				Θυμάμε μπήκα μέσα με το δεξί! Το σπίτι πεντακάθαρο, συγιρισμένο! Δεν υπήρχε ίχνος σκόνης. Κάθομαι στην καρέκλα και 
				εσυ στο κρεβάτι και ξεκινήσαμε χωρίς χάσιμο χρόνου να ανταλλάσουμε ιδέες, απόψεις, αξίες. Ξαφνικά η καρδία μου
				μου ψυθιρίζει "φίλα την..". Όσο το κρατούσα τόσο περισσότερο φώναζε και με παρακαλούσε να βρω το φάρρος να το κάνω.
				Εκεί ήταν η στιγμή που μου λες να κάτσω δίπλα σου στο κρεβάτι..
			</p>

			<br />

			<p
				className='p-container'
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[2]}px)`}}
				onClick={() => handleUnblur(2)}
			>
				Από πριν το είχα αποφασίσει τι να θέλω να κάνω. Η ομορφιά σου, οι απόψεις και αξίες αλλά και ακόμη κάτι..
				Τα μάτια σου. Εκείνα τα μάτια ήταν σαν νε με τραβούσαν, με εγκλόβισαν. ότι πυθανές κινήσης και να είχα για
				να κρατιθώ χάθηκαν από τι στιγμή που βρέθηκα στην αγκαλιά σου, ξαπλομένη στο κρεβάτι. Check mate.. Το αντρικό
				ένστικτο και η λογική με τραβούσε στο να θέλω να σε κινηγήσω. Να σε κατακτήσω. Και έτσι και έκανα. Σε ανταπόδωσα
				με θερμή αγκαλιά και σε χαϊδευα στο κεφάλι. Έπιτα το σύκωσα, σε κοίταξα κατάματα σαν να μην υπάρχει αύριο και 
				σε φίλισα. Γεύτικα τα υπέροχα σου χείλι.
			</p>

			<br />

			<p
				className='p-container'
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[3]}px)`}}
				onClick={() => handleUnblur(3)}
			>
				Η καρδιά μου, όσο γευόταν τα χείλη σου, ζητούσε κι άλλο. Εξάλλου, συνειδητοποίησα πως φιλάς 
				με τρόπο μαγικό. Μόνο που επικαλούμαι αυτές τις αναμνήσεις, μου λείπεις ακόμα περισσότερο. Κάθε 
				δευτερόλεπτο που ενώνονταν τα χείλη μας έμοιαζε με δέκα χρόνια στον παράδεισο. Σε ήθελα ολοένα και 
				πιο πολύ. Πίσω από κάθε στιγμή κρυβόταν αναμφισβήτητο πάθος. Ένα πάθος που μας ένωνε, που προς εμένα
				με κάνει να θέλω να τα δώσω όλα. Να σε προστατεύω, να σε προσέχω, να κάνω τις επόμενες μέρες να είναι
				οι καλύτερες της ζωής σου. Φυσικά εξαρτάται και απο ποιό είναι το θέλημα του Κυρίου. Αλλά και απο σένα..
			</p>

			<br />

			<p
				className='p-container'
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[4]}px)`}}
				onClick={() => handleUnblur(4)}
			>
				Στη συνέχεια, αφού έφυγα στον δρόμο για να πάω σπίτι, η καρδιά μου φώναζε: "Σε παρακαλώ, Σπύρο... μην 
				τη χάσεις! Αυτή με κάνει τόσο χαρούμενο, μου δίνει τη δυνατότητα να θεραπεύσω τα παιδικά μου τραύματα...". 
				Και έτσι, ήταν φανερό πως πρέπει να είμαι πραγματικά ευγνώμων για το ποια είναι η Χρύσα… για το ποια είσαι εσύ.
				Από τότε, κάθε βράδυ, ανεξάρτητα από το πώς πήγε η μέρα, εύχομαι να με επιλέξεις ξανά αύριο. Να μου χαρίσεις 
				ακόμη μια μέρα με αυτό το χαμόγελο και τη μεταδοτική σου καλή διάθεση. Κατάλαβα πως σε χρειάζομαι στη ζωή μου.
			</p>

			<br />

			<button className='myBtn' onClick={() => navigate('/seventh')}>Go to seventh Page</button>

			<br />
		</div>
	)
}