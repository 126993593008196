import React, { useState, useEffect } from 'react';
import Agios from '../images/agios-nektarios.jpg';
import Lord from '../images/Lord.jpg';
import Ntantogian from '../images/ntantogian.jpg';
import Us from '../images/us.jpg';
import { useNavigate } from 'react-router-dom';

export default function SeventhPage(){

	const images = [Agios, Lord, Ntantogian, Us];
	const navigate = useNavigate();

	const [cards, setCards] = useState([]);
	const [flippedCards, setFlippedCards] = useState([]);
	const [matchedCards, setMatchedCards] = useState([]);

	useEffect(() => {
		const shuffledImages = [...images, ...images]
			 .sort(() => Math.random() - 0.5)
			 .map((image, index) => ({ id: index, image, flipped: false }));
		
		setCards(shuffledImages);
  }, []);

  useEffect(() => {
	if (matchedCards.length === images.length * 2) {
		 setTimeout(() => {
				alert("🎉 Well done! You matched all cards! 🎉");
				goNext();  
		 }, 500);
	}
}, [matchedCards]);

const goNext = () => {
	navigate('/eighth');
}

  const handleCardClick = (index) => {
	if (flippedCards.length === 2 || matchedCards.includes(index)) return;

	const newCards = [...cards];
	newCards[index].flipped = true;
	setCards(newCards);
	
	const newFlippedCards = [...flippedCards, index];
	setFlippedCards(newFlippedCards);

	if (newFlippedCards.length === 2) {
		 setTimeout(() => checkMatch(newFlippedCards), 1000);
	}
};

const checkMatch = ([firstIndex, secondIndex]) => {
	if (cards[firstIndex].image === cards[secondIndex].image) {
		 setMatchedCards([...matchedCards, firstIndex, secondIndex]);
	} else {
		 const newCards = [...cards];
		 newCards[firstIndex].flipped = false;
		 newCards[secondIndex].flipped = false;
		 setCards(newCards);
	}
	setFlippedCards([]);
};

const resetGame = () => {
	const shuffledImages = [...images, ...images]
		 .sort(() => Math.random() - 0.5)
		 .map((image, index) => ({ id: index, image, flipped: false }));

	setCards(shuffledImages);
	setFlippedCards([]);
	setMatchedCards([]);
};

	return(
		<div className="App">
            <div className="game-grid">
                {cards.map((card, index) => (
                    <div 
                        key={index} 
                        className="card" 
                        onClick={() => handleCardClick(index)}
                        style={{
                            background: card.flipped || matchedCards.includes(index) 
                                ? `url(${card.image}) center/cover` 
                                : 'linear-gradient(135deg, #ff758c, #ff2e63)'
                        }}
                    />
                ))}
            </div>
				<button onClick={resetGame} className='myBtn'>Play Again!</button>
				<button className='myBtn' onClick={() => navigate('/')}>Start reading from the start!</button>
				<br />
        </div>
	)
}