import React, { useState, useEffect } from 'react';

const wordList = [
  "love", "queen", "Jesus", "I", "you", "program", "code", "happy", "island", "jump",
  "food", "beautiful", "Saint", "notebook", "ocean", "pink", "work", "woman", "church", "yolo"
];

function getRandomWords(count) {
  return Array.from({ length: count }, () =>
    wordList[Math.floor(Math.random() * wordList.length)]
  );
}

export default function Game2() {
  const [words, setWords] = useState(getRandomWords(10));
  const [currentInput, setCurrentInput] = useState("");
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [finished, setFinished] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [wpm, setWpm] = useState(0);

  useEffect(() => {
    if (currentWordIndex === words.length) {
      setFinished(true);
      const endTime = new Date().getTime();
      const timeTaken = (endTime - startTime) / 60000; // Convert ms to minutes
      setWpm((words.length / timeTaken).toFixed(2));
    }
  }, [currentWordIndex, words.length, startTime]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCurrentInput(value);

    if (!startTime) {
      setStartTime(new Date().getTime());
    }

    if (value.endsWith(" ")) {
      const trimmedValue = value.trim();
      if (trimmedValue === words[currentWordIndex]) {
        setCorrectCount((prev) => prev + 1);
      }
      setCurrentInput("");
      setCurrentWordIndex((prev) => prev + 1);
    }
  };

  return (
    <div className="App">
      {finished ? (
        <h2>Finished! Accuracy: {((correctCount / words.length) * 100).toFixed(2)}% | WPM: {wpm}</h2>
      ) : (
        <>
		  	 <h1 className='title'>QueenType!</h1>

			 <br />

          <p className='p-container'>{words.join(" ")}</p>
          <input
            type="text"
            value={currentInput}
            onChange={handleInputChange}
				className='myInput'
            autoFocus
          />
        </>
      )}
    </div>
  );
}
