import React, { useState } from 'react';
import '../Game3.css';

export default function Game3() {
  const [score, setScore] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [message, setMessage] = useState('');
  const [smessage, setSmessage] = useState('');

  const messages = [
    '"Δεν μπορείς να αποφύγεις αυτούς που σε ενοχλούν, αλλά μπορείς να τους αντιμετωπίσεις με γαλήνη."',
    '"Δεν βλάπτομαι από τον εχθρό μου, αλλά από τη δική μου αντίδραση σε αυτόν."',
    '"Μη νικιέσαι από το κακό, αλλά νίκα το κακό με το καλό."',
    '"Καλύτερα να υπομένεις τον κακό με υπομονή, παρά να τον πολεμάς με θυμό."',
    '"Ο Κύριος θα πολεμήσει για σας, κι εσείς θα μείνετε ήσυχοι."',
    'Τ"Υπομένετε ο ένας τον άλλον και συγχωρείτε ο ένας τον άλλον, αν κάποιος έχει παράπονο εναντίον κάποιου."'
  ];

  const smallMessages = [
	'Μήνυμα: Η εσωτερική ηρεμία σου είναι η ασπίδα σου απέναντι σε όσους σε δυσκολεύουν.',
	'Μήνυμα: Η δύναμη σου βρίσκεται στον έλεγχο των δικών σου αντιδράσεων, όχι στις πράξεις των άλλων.',
	'(Ρωμαίους 12:21) Μήνυμα: Αντιμετώπισε το κακό που σου κάνουν με θετικές πράξεις, αντί να αφήνεις τον θυμό να σε κυριεύει.',
	'(Εκκλησιαστής 7:9) Μήνυμα: Η υπομονή είναι ανώτερη από τον θυμό όταν αντιμετωπίζεις δυσκολίες από άλλους.',
	'(Έξοδος 14:14) Μήνυμα: Εμπιστεύσου τη θεϊκή δικαιοσύνη αντί να παίρνεις την κατάσταση στα χέρια σου.',
	'(Κολοσσαείς 3:13) Μήνυμα: Η υπομονή και η συγχώρεση είναι ο τρόπος να αντιμετωπίζεις όσους σε δυσκολεύουν.'
  ]

  const handleClick = () => {
    if (gameStarted && score < 6) {
      setScore(prev => prev + 1);
      setMessage(messages[score]); // Εμφανίζει το αντίστοιχο μήνυμα
		setSmessage(smallMessages[score]);
    }
    if (score + 1 === 6) {
      setGameStarted(false); // Τελειώνει το παιχνίδι στα 6 κλικ
    }
  };

  const startGame = () => {
    setScore(0);
    setGameStarted(true);
    setMessage(''); // Αρχικό μήνυμα κενό πριν το πρώτο κλικ
	 setSmessage('');
  };

  return (
    <div className="App">
      <h1 className='title'>Παιχνίδι Κλικ</h1>
      {!gameStarted && score === 0 && (
        <button className="game-button" onClick={startGame}>Ξεκίνα!</button>
      )}
      {gameStarted && score < 6 && (
        <>
          <p className='game-p'>{message}</p>
          <p className='game-smallP'>{smessage}</p>
          <button className="game-button" onClick={handleClick}>Κλικ εδώ!</button>
        </>
      )}
      {!gameStarted && score === 6 && (
        <>
          <p className='game-p'>Σε αγαπάω με όλη μου την καρδιά!</p>
          <button className="game-button" onClick={startGame}>Παίξε ξανά</button>
        </>
      )}
    </div>
  );
}