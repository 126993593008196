import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function FifthPage(){

	const navigate = useNavigate();

	const [blurState, setBlurState] = useState([5, 5, 5, 5, 5]);

	const [answer, setAnswer] = useState(null);

	const [katalaboun, setKatalaboun] = useState(false);
	const [therapefso, setTherapefso] = useState(false);
	const [somata, setSomata] = useState(false);
	const [pnevmatikh, setPnevmatikh] = useState(false);
	const [gennhmenow, setGennhmenos] = useState(false);
	const [patera, setPatera] = useState(false);
	const [pisteyei, setPisteyei] = useState(false);
	const [erga, setErga] = useState(false);

	 const checkKatalaboun = () => {
		if(answer === "καταλάβουν"){
			setKatalaboun(true);
		}
	 }
	 const checkTherapeyso = () => {
		if(answer === "θεραπεύσω"){
			setTherapefso(true);
		}
	 }
	 const checkSomata = () => {
		if(answer === "σώματα"){
			setSomata(true);
		}
	 }
	 const checkPnevmatikh = () => {
		if(answer === "πνευματική"){
			setPnevmatikh(true);
		}
	 }
	 const checkGennhmenos = () => {
		if(answer === "γεννημέμος"){
			setGennhmenos(true);
		}
	 }
	 const checkPatera = () => {
		if(answer === "Πατέρα"){
			setPatera(true);
		}
	 }
	 const checkPisteyei = () => {
		if(answer === "πιστεύει"){
			setPisteyei(true);
		}
	 }
	 const checkErga = () => {
		if(answer === "έργα"){
			setErga(true);
		}
	 }

	 

	 const handleAnswerClick = (e, index, word) => {
		e.preventDefault();
		setAnswer(word);
	 };

	 const checkAnswers = () => {
		if(katalaboun === true && therapefso === true && somata === true 
			&& pnevmatikh === true && gennhmenow === true 
			&& patera === true && pisteyei === true && erga === true){
				alert("Well done!");
		}
	 }

	 const words = [
		"Πατέρα",
		"πιστεύει",
		"καταλάβουν",
		"πνευματική",
		"γεννημέμος",
		"σώματα",
		"έργα",
		"θεραπεύσω",
	 ];

	const handleUnblur = (index) => {
		const newBlurState = [...blurState];
		newBlurState[index] = 0; // Unblur the clicked paragraph
		setBlurState(newBlurState);
	};

	return(
		<div className='App'>
			
			<h1 className='title'>I started getting addicted...</h1>

			<br/>

			<p
				className='p-container'
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[0]}px)`}}
				onClick={() => handleUnblur(0)}
				>
					Απο εκεινη την δευτέρα, όταν μου ζήτησες να πάμε βόλτα μετά απο 10 κουραστικές ώρες, ξεκίνησες συστηματικά
					να κάνεις μία πράξει όπου είναι και η αδυναμία μου. Οι αγκαλιές! Κάθε φορά όπου ήταν να πούμε
					αντίο ο ένας στον άλλος (κάτι που μεχρι σήμερα είναι δυσάρεστη εμπιρία), θα έριχνες τον εαυτό σου στα χέρια μου,
					θα έπεφταις στην αγγαλιά μου. Αυτό με κάνει να εσθάνομαι πως έχω αξία, πως μετράω. Μου δώρησες αυτήν την
					αξία που δεν μου αναλογή. Σε ευχαριστώ για αυτό..

			</p>

			<br/>

			<p
				className='p-container'
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[1]}px)`}}
				onClick={() => handleUnblur(1)}
			>
				Τώρα συνεχίζουμε την ιστορία μας, όπου φτάνουμε σε ένα σημείο που δεν έχει επιστροφή. Σημείο όπου οι καρδίες μας
				ενωθήκαν, και ως αποτέλεσμα, προφανέστατα, το νέο κεφάλαιο περιέχει και τις δύο ψυχές μας τρελαίς η μια για την αλλη.
				Τρίτη είναι ακόμη χειρότερη ημέρα. Και πάλι το βράδυ, η κούραση δεν σου στάθηκε εμπόδιο. Μου πρώτηνες να πάμε βόλτα, όπου
				δέχτηκα με μεγάλη χαρά. Εκεί ανταλλάξαμε ιδέες, γνωριστήκαμε περισσότερο και πριν φύγω με αγκαλιαστήκαμε. Εδώ παρατήρισα κάτι,
				κάθε αγκαλιά ήταν πιό θερμή απο την προιγούμενη. Αυτό μπορώ να πω πως ισχυεί μέχρι σήμερα.
			</p>

			<br />

			<p
				className='p-container'
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[2]}px)`}}
				onClick={() => handleUnblur(2)}
			>
				Από την στιγμή που μπήκα στο λεωφορείο εως σήμερα η καρδία μου μιλούσε για σένα στο μυαλό μου. Το κατάλαβα διότι
				ησουν απο τότε πάντα στο μυαλό μου. Δεν μπορούσα να σε βγάλω. Χαράχθηκε η εικόνα του ωραίου προσώπου σου, να
				χαμογελάς, να γελάς, να μου λες τις απόψεις σου, να με αγκαλιάζεις..Πραγματικά είσαι υπέροχη έλεγα στον εαυτό μου
				με τεράστια εκπλυξη. Δυστυχώς η απιστία μου δεν με επέτρεψε να το χαρώ όσο θα έπρεπε, για λόγους που σου ανέφερα..
				Η σκέψη πως αυτό το κεφάλαιο θα είναι προσωρινό.
			</p>

			<br />

			<p
				className='p-container'
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[3]}px)`}}
				onClick={() => handleUnblur(3)}
			>
				Ο Θεός όμως δεν με άφυσε παρόλο τις απιστίας μου. Αυτό φανερώνεται με τις πράξεις μου προς εσένα. Εκεί ήταν το σημέιο
				που ήθελα να αρχίσω να κάνω πράγματα για σένα, το σημείο που ξεκίνησα να σε υπηρετώ, να σε βάζω στην θέση της
				βασίλισσας στην καρδία και το μυαλόμου. Αφού την επόμενη βδομάδα, Τρίτη ξύπνησα πήγα μάθημα και μετα έρτεξα! να φτάσω
				το λεωφορείο να πάω σπίτι και να σου κάνω κρέπες. Με αντάλλαγμα κάτι που αξίζει πολύ περισσότερο απο την πάραξη μου αυτή,
				ένα αστραφτερό χαμόγελο σου και την ευγνωμοσήνη στα μάτια σου. όταν βίωσα αυτά τα δύο ερωτεύτηκα. Δεν μπορούσα να σταματήσω
				να κοιτάω τα δυό σου μάτια, αλλά και εκείνα τα ωραία χείλη σου. Ομολογώ πως μου μπήκαν ιδέες. Ήθελα να γευτώ τα γλυκά σου
				χείλη.
			</p>

			<br />

			<p
				className='p-container'
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[4]}px)`}}
				onClick={() => handleUnblur(4)}
			>
				Πριν πάμε στην επόμενη σελίδα ας παίξουμε και άλλο παιχνίδι βασίλισσα μου! πιό κάτω είναι στοίχει απο την καινή
				διαθήκη! Θα πρέπει να επιλέξεις τις λέξεις που πιστεύεις πως τεργιάζουν στην πρόταση!
			</p>

			<br />

		<p
			className="p-container"
			style={{ color: "rgb(22, 22, 22)", cursor: "default"}}
      >
        - "Έχει τυφλώσει τα μάτια τους και πωρώσει την καρδία τους, ώστε να μην δουν με τα 
		  μάτια και <span style={{filter: katalaboun ? "none" : "blur(10px)", cursor: "pointer"}} onClick={() => checkKatalaboun()}>
			καταλάβουν</span> την καρδία και επιστρέψουν γιά 
			να τους <span style={{filter: therapefso ? "none" : "blur(10px)", cursor: "pointer"}} onClick={() => checkTherapeyso()}>
				θεραπεύσω</span>"
      </p>

      <br />

      <p
        className="p-container"
        style={{ color: "rgb(22, 22, 22)", cursor: "default"}}
      >
        - "Σας παρακαλώ, λοιπόν, αδελφοί, εν ονόματι της ευσπλαχνίας του Θεού, να προσφέρετε τα 
		  <span style={{filter: somata ? "none" : "blur(10px)", cursor: "pointer"}} onClick={() => checkSomata()}>
			σώματα</span> σας ζωντανά θυσία, 
		  αμόλυντη, ευπρόσδεκτη στον Θεό, που είναι η <span style={{filter: pnevmatikh ? "none" : "blur(10px)", cursor: "pointer"}}
		   onClick={() => checkPnevmatikh()}>πνευματική</span> σας λατρεία"
      </p>

      <br />

      <p
        className="p-container"
        style={{ color: "rgb(22, 22, 22)", cursor: "default"}}
      >
        - "Ο Υιος του Θεού είναι εικών του αόρατου Θεού, <span style={{filter: gennhmenow ? "none" : "blur(10px)", cursor: "pointer"}}
		   onClick={() => checkGennhmenos()}>γεννημέμος</span> από τον 
			<span style={{filter: patera ? "none" : "blur(10px)", cursor: "pointer"}} onClick={() => checkPatera()}>Πατέρα
				</span> πρίν από τη δημιουργία του σύμπαντος"
      </p>

      <br />

      <p
        className="p-container"
        style={{ color: "rgb(22, 22, 22)", cursor: "default"}}
      >
        - "Τι ωφέλεια έχει κάποιος που λέγει ότι <span style={{filter: pisteyei ? "none" : "blur(10px)", cursor: "pointer"}} 
		  onClick={() => checkPisteyei()}>πιστεύει</span>, αδελφοί μου, 
		  αλλά δεν το αποδεικνύει με <span style={{filter: erga ? "none" : "blur(10px)", cursor: "pointer"}} 
		  onClick={() => checkErga()}>έργα</span>; Μπορεί μόνο η πίστη να τον σώσει;"
      </p>

      <br />

      <div>
        <h3>Επιλέξτε τις σωστές λέξεις:</h3>
        {words.map((word, index) => (
			<a
			className='btn btn-white btn-animate'
			href='#'
			key={index}
			onClick={(e) => handleAnswerClick(e, index, word)}
			
			>
			{word}
			</a>
        ))}
      </div>

      <br />

      <button
			className='answer-button'
			onClick={() => {checkAnswers()}}>
        Ελέγξτε τις Απαντήσεις
      </button>

		<br />

		<br />

		<button className='myBtn' onClick={() => navigate('/sixth')}>Go to sixth Page</button>

		<br />
			
		</div>
	)
}