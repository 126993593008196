import './App.css';
import FourthPage from './components/FourthPage';
import FifthPage from './components/FifthPage';
import SecondPage from './components/SecondPage';
import ThirdPage from './components/ThirdPage';
import SixthPage from './components/SixthPage';
import SeventhPage from './components/SeventhPage';
import EighthPage from './components/EighthPage';
import NinthPage from './components/NinthPage';
import Game2 from './components/Game2';
import Game3 from './components/Game3';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function App() {

  const navigate = useNavigate();
  
  const [inputValue, setInputValue] = useState('');
  const [firstBool, setFirstBool] = useState(false);
  const [myColor, setMyColor] = useState('black');
  const [blurState, setBlurState] = useState([5, 5, 5, 5]); // Initial blur state for each paragraph
  const [pageLoaded, setPageLoaded] = useState(false); // State to track if the page has loaded after 30 seconds

  const correctSQL = "SELECT day FROM learningToLove WHERE day_num = 6";
  const helpSelect = "SELECT";
  const helpDay = "SELECT day";
  const helpFROM = "SELECT day FROM";
  const helpLearning = "SELECT day FROM learningToLove";
  const helpWhere = "SELECT day FROM learningToLove WHERE";
  const helpNum = 'SELECT day FROM learningToLove WHERE day_num';

  useEffect(() => {
    if (inputValue.trim() === helpSelect) {
      setMyColor('pink');
    } else if (inputValue.trim() === helpDay) {
      setMyColor('pink');
    } else if (inputValue.trim() === helpFROM) {
      setMyColor('pink');
    } else if (inputValue.trim() === helpLearning) {
      setMyColor('pink');
    } else if (inputValue.trim() === helpWhere) {
      setMyColor('pink');
    } else if (inputValue.trim() === helpNum) {
      setMyColor('pink');
    } else if (inputValue.trim() === correctSQL) {
      setFirstBool(true);
      alert('well done!');
    } else {
      setMyColor('black');
    }
  }, [inputValue]);

  //const time = 10000;
  const time = 0;

  useEffect(() => {
    // Set the page to load after 30 seconds
    const timer = setTimeout(() => {
      setPageLoaded(true);
    }, time); // 40000

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // Function to handle unblurring of a specific paragraph
  const handleUnblur = (index) => {
    const newBlurState = [...blurState];
    newBlurState[index] = 0; // Unblur the clicked paragraph
    setBlurState(newBlurState);
  };

  return (
    <Routes>
      <Route
        path='/'
        element={
          <div className="App">
            {/* If the page hasn't loaded, display a white background with centered text */}
            {!pageLoaded ? (
              <div style={{ backgroundColor: 'white', height: '100vh',width: '100vw',  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h1>Η σελίδα Νέμεσις έκλεισε</h1>
              </div>
            ) : (
              <div className='App'>
                {firstBool ? (
                  <SecondPage />
                ) : (
                  <div className='App'>
                    <h1 className="title">Hello Love 😘❤️</h1>

                    <p
                      className="p-container"
                      style={{ filter: `blur(${blurState[0]}px)` }}
                      onClick={() => handleUnblur(0)} // Unblur this paragraph when clicked
                    >
                      I hope that reading this will open your heart, just as you have opened mine. 
                      I’m not one to express myself this way—I'm often quite lazy and reserved—but I prayed 
                      to our Lord to guide my soul on its journey to find love, to learn how to love, and to 
                      appreciate all things, whether <big>BIG</big> or <small>small</small>. The purpose of this 
                      letter, inspired by the book Father Nikolas gave you, i simply to say 'Thank you.' Thank you for 
                      choosing to stand by my side during this brief yet meaningful time we've shared—knowing it could be our last.
                    </p>

                    <br />

                    <p
                      className="p-container"
                      style={{ filter: `blur(${blurState[1]}px)` }}
                      onClick={() => handleUnblur(1)} // Unblur this paragraph when clicked
                    >
                      The first time I saw you, you were talking to a friend, your beautiful smile lighting up the room. 
                      At that moment, I had no idea just how extraordinary you truly are—and even now, I’m still discovering 
                      the many layers of your remarkable character. Later that day, we shared a coffee, and I began to learn more 
                      about you. I found out that you had a challenging job, one you genuinely enjoyed. It was clear to me then
                      that you thrive on challenges. But what stood out the most, besides your smile that never faded, was 
                      the way you seemed to embrace every moment with positivity. From the start, your warm voice, that 
                      captivating smile, and your love for a challenge piqued my interest in ways I never expected
                    </p>

                    <br />

                    <p
                      className="p-container"
                      style={{ filter: `blur(${blurState[2]}px)` }}
                      onClick={() => handleUnblur(2)} // Unblur this paragraph when clicked
                    >
                      At that time, I was on a journey to meet new people, convincing myself that I needed to 
                      leave my past friendships behind for reasons you already understand. I was also taking those first, 
                      tentative steps toward a greater truth—Jesus Christ of Nazareth, the Son of God ☦️. It was during this 
                      period that I crossed paths with the person who unknowingly became the catalyst for our meeting, the spark 
                      that ignited the connection between our souls. His name was George 😅. Not someone I’d choose to keep close, 
                      but he was the one who organized 'THE' gathering where I had the chance to meet you.
                    </p>

                    <br />

                    <p
                      className='p-container'
                      style={{ filter: `blur(${blurState[3]}px)` }}
                      onClick={() => handleUnblur(3)} // Unblur this paragraph when clicked
                    >
                      Changing the subject! Since I know you like SQL, I made a quick game for you! Let's say there is a table that 
                      stores some very important days. Your task is to write a query to 'select' the day from the table. The table is 
                      named learningToLove, and it has two columns: day and day_num. Select the day where day_num matches a specific value.
                    </p>

                    <input
                      className='input_box'
                      autoComplete='off'
                      id='first-box'
                      onChange={handleInputChange}
                      value={inputValue}
                      style={{ color: myColor }}
                    />

                    <button className='myBtn' onClick={() => navigate('/second')}>Click to go to the second page</button>

                    <button className='myBtn' onClick={() => navigate('/eighth')}>Click to skip to new part my Queen</button>

                    <br/>
                  </div>
                )}
              </div>
            )}
          </div>
        }
      />
      <Route path='/second' element={<SecondPage />} />
      <Route path='/third' element={<ThirdPage />} />
      <Route path='/fourth' element={<FourthPage />} />
      <Route path='/fifth' element={<FifthPage />} />
      <Route path='/sixth' element={<SixthPage />} />
      <Route path='/seventh' element={<SeventhPage />} />
      <Route path='/eighth' element={<EighthPage />} />
      <Route path='/game2' element={<Game2 />} />
      <Route path='/ninth' element={<NinthPage />} />
      <Route path='/game3' element={<Game3 />} />
    </Routes>
  );
}

export default App;
