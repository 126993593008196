import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function EighthPage(){
	const [blurState, setBlurState] = useState([5, 5, 5, 5, 5]);

	const navigate = useNavigate();

	const handleUnblur = (index) => {
		const newBlurState = [...blurState];
		newBlurState[index] = 0;
		setBlurState(newBlurState);
	}

	return(
		<div className='App'>
			<h1 className='title'>Η χαρά μας, η στεναχώρια τους</h1>

			<br />

			<p 
				className='p-container'
				style={{color:'rgb(22, 22, 22)', filter:`blur(${blurState[0]}px)`}}
				onClick={() => handleUnblur(0)}
			>
				Οι μέρες κυλάνε, και κάθε μέρα είναι καλύτερη από την προηγούμενη. Ερχόμαστε πιο κοντά, ενδυναμώνουμε τη φρέσκια
				ανθρώπινη σχέση που μόλις φτιάξαμε. Ήταν όμως έτοιμο αυτό το λεπτό σχοινάκι, με την ωραία μυρωδιά του "καινούργιου", να 
				αντέξει το εμπόδιο που θα εμφανιστεί κατάμουτρα; Ο διάβολος σιχάθηκε να βλέπει χαμόγελα και να ακούει
				γέλια… Είδε τον ήλιο να χαίρεται και αποφάσισε να κάνει το φεγγάρι να κλάψει…
			</p>

			<br />

			<p
				className='p-container'
				style={{color:'rgb(22, 22, 22)', filter:`blur(${blurState[1]}px)`}}
				onClick={() => handleUnblur(1)}
			>
				Βρήκε δύο θύματα, τα οποία χρησιμοποίησε για να επιτεθεί στο καημένο σχοινάκι. Και ταυτόχρονα σε σένα, στην ψυχολογία 
				σου. Η μαμά σου ήταν στην Κέρκυρα, έμενε μαζί σου. Εδώ και καιρό δέχεσαι ψυχολογική και λεκτική βία από αυτήν. 
				Δεν αρκούσε μόνο αυτό, ξάφνικά έρχεται και η μαχαιριά!. Ένας "καλός" (και τώρα συγχωρεμένος) φίλος σου, 
				ηττημένος από τον εγωισμό του και περήφανος, βρήκε τη μαμά σου στον δρόμο. Ο Θεός
				το επέτρεψε διότι στο τέλος θα γίνει το πλάνο του!
			</p>

			<br />

			<p
				className='p-container'
				style={{color:'rgb(22, 22, 22)', filter:`blur(${blurState[2]}px)`}}
				onClick={() => handleUnblur(2)}
			>
				Έτσι, ο συγχωρεμένος ψευδομαρτυρεί στη μαμά σου για τον χαρακτήρα μου, δημιουργώντας μια κακή 
				πρώτη εντύπωση. Παράλληλα, μαθαίνει και μια πληροφορία—ότι βλέπεις κάποιο αγόρι—τη στιγμή που δεν ήσουν 
				έτοιμη να το αποκαλύψεις. Έτσι βρέθηκες σε ένα δίλημμα. Αξίζει να υποστείς φωνές, φασαρείες, τσακομούς,
				διαφωνίες, συνέπεις, και ταυτόχρονα να χάσεις και άλλους φίλους. Όλα αυτά για ένα παιδί που μόλις γνώρισες. Ας μην
				ξεχάσουμε και τι εμπειρίες είχες από άλλα αγόρια..εγώ θα ξεχώριζα; με τόσο λίγο γνωριζόμασταν, δεν μπορούσες με
				σιγουριά να πεις πως "Ο Σπύρος δεν είναι απλά ενα αγόρι..είναι Άντρας!".
			</p>

			<br />

			<p
				className='p-container'
				style={{color:'rgb(22, 22, 22)', filter:`blur(${blurState[3]}px)`}}
				onClick={() => handleUnblur(3)}
			>
				Οι λογισμοί χτυπάνε δυνατά. Αποδυναμόνουν τα χέρια σου που κρατάνε το σχοινή..καθώς και αυτο δέχεται επίθεση απο 
				ψαλίδι. Αδύνατο να δω την ξαγνική σου απομάκρυνση. Με απόφευγες και με την οπτική σου και τα γεγονότα, ήταν δικαιολογιμένα.
				Προφανώς δεν αξίζει ένα νέο αγόρι, μέσα στο μυστήριο για το χαρακτήρα του να αξίζει να περάσεις απο την κόλαση και να
				χάσεις την ηρεμία σου. Έτσι και γι'αυτό ξεχωριστά πηγέναμε στην λέσχη. Χώρια καθόμασταν, με μόνο επικοινωνία λίγα βλέμματα..
			</p>

			<br />

			<p
				className='p-container'
				style={{color:'rgb(22, 22, 22)', filter:`blur(${blurState[4]}px)`}}
				onClick={() => handleUnblur(4)}
			>
				Πραγματικά, δεν θέλω να σου γίνομαι βάρος. Τότε δεν ήξερα γιατί απομακρύνθηκες—ίσως απλά δεν σου άρεσα 
				τόσο. Ίσως να μη με ήθελες. Ίσως να είχες κάποιον άλλον στο μυαλό σου. Ήταν ξεκάθαρο πως έπρεπε κι εγώ 
				να αφήσω ένα σχοινί που, βαθιά μέσα μου, ήλπιζα να μη σπάσει ποτέ. Ο διάβολος είχε στόχο να μας κάνει ξένους.
				Να κάψει αυτo το σχοινή. Έβαλε τα δυνατά του. Εν τέλει, η ζωή νίκησε τον θάνατο. Ο Θεός έδωσε εντολή! Πιστεύω η
				Αγία Παρασκευή μέρα νύχτα προσευχόμενη στο Θεό εν τέλει φανέρωσε αυτός πως όλο αυτο ήταν στα σχέδοια του!
			</p>

			<br />

			<button className='myBtn' onClick={() => navigate('/Game2')}>Play MonkeyType Game</button>

			<br />

			<button className='myBtn' onClick={() => navigate('/ninth')}>Go Next Page</button>

			<br />
		</div>
	)
}